
import { Appbar } from '../pages/AppBar'
import {Redirect} from "react-router-dom"
import {Route,Switch} from 'react-router-dom';
import CategoriesUI from './CategoriesUI.js';
import SubCategories from './SubCategories';
import ItemsUI from './ItemsUI';
import DisplayComments from './DisplayComments';
import RestInfo from './RestInfo'; 
import Dashboard from './Dashboard'; 
import DashboardViews from './DashboardViews'; 
import ChangePassword from './ChangePassword'; 
import ColorCustomization from './ColorCustomization'; 
import QRCodeBuilder from './QRCodeBuilder '; 
import MyRestaurant from './MyRestaurant'; 

function Home({isAuthorized}){ 
    if (!isAuthorized)
    { 
        return <Redirect to="/login"></Redirect>
    } 
    return (
    <div>
        <Appbar/>
        <Switch>
            <Route path='/' component={CategoriesUI} exact/> 
            <Route path='/sub-categories' component={SubCategories} /> 
            <Route path='/ItemsUI' component={ItemsUI} /> 
            <Route path='/DisplayComments' component={DisplayComments} /> 
            <Route path='/RestInfo' component={RestInfo} /> 
            <Route path='/Dashboard' component={Dashboard} /> 
            <Route path='/DashboardViews' component={DashboardViews} /> 
            <Route path='/ChangePassword' component={ChangePassword} /> 
            <Route path='/ColorCustomization' component={ColorCustomization} /> 
            <Route path='/QRCodeBuilder' component={QRCodeBuilder} /> 
                <Route path='/MyRestaurant' component={MyRestaurant} /> 

 
        </Switch>
    </div>
    )
}  
export default Home;
