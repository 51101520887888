import './App.css';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import {useEffect,useState} from 'react' 
import LogIn from './pages/Login'; 
import Home from './pages/Home'; 
import Snackbar from '@material-ui/core/Snackbar'; 
 import Menu from './pages/Menu';
import OriginSoft from './pages/OriginSoft'
import  RotateLoader  from 'react-spinners/RotateLoader' 


function App() { 
  const [isAuthorized,setIsAuthorized] = useState()
    const [open, setOpen] = useState(false);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }; 
    useEffect(() => {

        //clear session storage on refresh the page
        sessionStorage.clear(); 

    auth()
  },[])

  const auth = async () => {
    const JWT = localStorage.getItem("jwt"); 
    if(JWT){
      setIsAuthorized(true)
    }else{
      setIsAuthorized(false)
    }
  }
  if(typeof isAuthorized === 'undefined'){
    return <div 
    style={{
        position: 'absolute', left: '50%', top: '30%',
        transform: 'translate(-50%, -50%)'
    }}><RotateLoader   size={30}   margin={10} color={'#36d7b7'}></RotateLoader></div>
  } 
  return (
    <BrowserRouter>
    <div> 
    
     <Switch> 
      <Route path='/login' component={()=><LogIn setIsAuthorized={setIsAuthorized} handleClick={()=>setOpen(true)}/>}exact /> 
      <Route path="/Menu/:name"    component={() => <Menu/>} />
      <Route path="/OriginSoft"    component={() => <OriginSoft/>} />
      <Route path='/'  component={() => <Home isAuthorized={isAuthorized} />} />  
     </Switch>  
    </div> 
    </BrowserRouter>
  );
}

export default App;
